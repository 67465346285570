import React, { useEffect, useState } from "react";

// Hooks
import { useSelector, useDispatch } from "react-redux";
import { useFetch } from "../Hooks/useFetch";

// Libraries
import Select from "react-select";
// Import Swiper React components
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// Components
import { Link } from "react-router-dom";
import Offer from "./offer";
import CMap from "./map";
import useApi from "../Hooks/useApi";

// Utils
import { setCookie } from "../Utils/cookies";
import { updateFilters } from "../Store/action";

SwiperCore.use([Navigation, Pagination, Scrollbar]);

const Offers = ({ title, filters, jobs, link, map, updateLikeComponents, rerender, path_filtered, cookies_filtered, src, isHome, hide_map = false }) => {
    let dispatch = useDispatch();
    const lang = useSelector(state => state.global).lang;
    const userID = useSelector(state => state.global).userId;
    const { m767 } = useSelector(state => state.breakpoint);
    const { global: { colors } } = useSelector(state => state.global);

    const cookiesFilters = useSelector(state => state.global).filters;
    const [newFilters, setNewFilters] = useState('');

    const [selectIsOpen, setSelectIsOpen] = useState(false);

    const [values, setValues] = useState([]);
    const [needChange, setNeedChange] = useState(false);
    const [idFilter, setIdFilter] = useState([]);

    const [slice, setSlice] = useState(14);

    useEffect(() => {
        m767 ? setSlice(5) : setSlice(14);
    }, [m767]);

    const [results, setResults] = useState(jobs.list);

    // Save selected filters
    const [selected, setSelected] = useState({
        'tax_sector': [],
        'tax_contract': [],
        'tax_place': [],
        'tax_experience': [],
        'tax_degree': [],
        'tax_brands': [],
        'tax_department':[],
        'tax_job': [],
        'tax_city': [],
        'tax_country': [],
        'tax_channel': [],
        'jobcode': [],
        'tax_community_job': []
    });

    const [params, setParams] = useState(null);
    const [init, setInit] = useState(false);

    useEffect(() => {
        if (init === true) {
            let taxContract = cookies_filtered.tax_contract ? cookies_filtered.tax_contract : '';
            let taxExperience = cookies_filtered.tax_experience ? cookies_filtered.tax_experience : '';
            let taxSector = cookies_filtered.tax_sector ? cookies_filtered.tax_sector : '';
            let taxJob = cookies_filtered.tax_job ? cookies_filtered.tax_job : '';
            if (path_filtered) {
                if (src === 'jobDetails') {
                    if (path_filtered.tax_job) {
                        taxJob = path_filtered.tax_job;
                        taxSector = '';
                    } else {
                        taxJob = '';
                        taxSector = path_filtered.tax_sector ? path_filtered.tax_sector : '';
                    }
                }
                if (src === 'jobList') {
                    taxSector = path_filtered.tax_sector ? path_filtered.tax_sector : '';
                }
                if (src === 'article') {
                    if (path_filtered.tax_contract) {
                        taxContract = path_filtered.tax_contract;
                    } else {
                        taxContract = ''
                    }
                    if (path_filtered.tax_experience) {
                        taxExperience = path_filtered.tax_experience;
                    } else {
                        taxExperience = ''
                    }
                }
            }

            setParams({
                'lang': lang,
                'keyword': cookies_filtered.keyword ? cookies_filtered.keyword : '',
                'tax_sector': taxSector,
                'tax_contract': taxContract,
                'tax_place': cookies_filtered.tax_place ? cookies_filtered.tax_place : '',
                'tax_experience': taxExperience,
                'tax_degree': cookies_filtered.tax_degree ? cookies_filtered.tax_degree : '',
                'tax_brands': cookies_filtered.tax_brands ? cookies_filtered.tax_brands : '',
                'tax_department': cookies_filtered.tax_department ? cookies_filtered.tax_department : '',
                'tax_job': taxJob,
                'tax_city': cookies_filtered.tax_city ? cookies_filtered.tax_city : '',
                'tax_country': cookies_filtered.tax_country ? cookies_filtered.tax_country : '',
                'tax_channel': cookies_filtered.tax_channel ? cookies_filtered.tax_channel : '',
                'jobcode': cookies_filtered.jobcode ? cookies_filtered.jobcode : '',
                'tax_community_job': cookies_filtered.tax_community_job ? cookies_filtered.tax_community_job : '',
                'userID': userID !== null ? userID : '',
                'size': "15",
                'from': "0"
            });
        } else {
            setParams({
                'lang': lang,
                'keyword': path_filtered && path_filtered.keyword ? path_filtered.keyword : '',
                'tax_sector': path_filtered && path_filtered.tax_sector ? path_filtered.tax_sector : '',
                'tax_contract': path_filtered && path_filtered.tax_contract ? path_filtered.tax_contract : '',
                'tax_place': path_filtered && path_filtered.tax_place ? path_filtered.tax_place : '',
                'tax_experience': path_filtered && path_filtered.tax_experience ? path_filtered.tax_experience : '',
                'tax_degree': path_filtered && path_filtered.tax_degree ? path_filtered.tax_degree : '',
                'tax_brands': path_filtered && path_filtered.tax_brands ? path_filtered.tax_brands : '',
                'tax_department': path_filtered && path_filtered.tax_department ? path_filtered.tax_department : '',
                'tax_job': path_filtered && path_filtered.tax_job ? path_filtered.tax_job : '',
                'tax_city': path_filtered && path_filtered.tax_city ? path_filtered.tax_city : '',
                'tax_country': path_filtered && path_filtered.tax_country ? path_filtered.tax_country : '',
                'tax_channel': path_filtered && path_filtered.tax_channel ? path_filtered.tax_channel : '',
                'jobcode': path_filtered && path_filtered.jobcode ? path_filtered.jobcode : '',
                'tax_community_job': path_filtered && path_filtered.tax_community_job ? path_filtered.tax_community_job : '',
                'userID': userID !== null ? userID : '',
                'size': "15",
                'from': "0"
            });
        }
    }, [init]);

    useEffect(() => {
        if (typeof (cookies_filtered) !== 'undefined') {
            setInit(true);
        }
        // // returned function will be called on component unmount
        // return () => {
        //     setInit(false);
        // }
    }, [cookies_filtered]);


    useEffect(() => {
        if (params !== null) {
            //console.log(params);
            if (typeof src !== "undefined") {
                setNewFilters({ ...cookiesFilters, [src]: params, offer: params });
            }
        }
    }, [params, src]);

    useEffect(() => {
        if (newFilters !== '') {
            setCookie('filters', JSON.stringify(newFilters), 60);
            dispatch(updateFilters(newFilters));
        }
    }, [newFilters]);

    // Get Search API results
    const [loading, setLoading] = useState(false);
    const [paramsAPI, setParamsAPI] = useState('');
    const [searchPath, setSearchPath] = useState('');

    const [jobsFromSearch, areJobsFromSearch] = useState(false);
    const { response: resultsSearch, dataIsLoaded: resultsSearchIsLoaded } = useFetch('search/jobs', 'post', paramsAPI);

    // Places API
    const [updatedFilters, setUpdatedFilters] = useState(filters.items);
    const [paramsPlaces, setParamsPlaces] = useState({});
    const [dataFetchPlaces, isLoadedPlaces] = useApi(paramsPlaces);

    useEffect(() => {
        if ((jobsFromSearch === true || (cookies_filtered && typeof (cookies_filtered) !== 'undefined')) && params !== null) {
            // API Call
            setParamsAPI({ params });

            if (resultsSearchIsLoaded === true) {
                setLoading(false);
                if (Object.keys(resultsSearch).length > 0) {
                    // Results
                    if (resultsSearch.data.items.length > 0) {
                        setResults(resultsSearch.data.items);
                    } else {
                        setResults([]);
                    }
                } else {
                    // No results
                    setResults([]);
                }
            } else {
                setResults([]);
                setLoading(true);
            }


            let taxLocation = params.tax_country;
            if (taxLocation) {
                setParamsPlaces({
                    name: 'jobs/list/places',
                    method: 'post',
                    params: '?lang=' + lang + '&country=' + taxLocation
                });
            } else {
                setParamsPlaces({});
                setUpdatedFilters(filters.items);
            }
        }
    }, [jobsFromSearch, resultsSearch, params, resultsSearchIsLoaded]);


    useEffect(() => {
        if (isLoadedPlaces) {
            if (dataFetchPlaces.data) {
                let newArr = filters.items.map((item) => {
                    if (item.id === 'tax_place') {
                        return {...item, ['choices']: dataFetchPlaces.data}; // eslint-disable-line
                    } else {
                        return item;
                    }
                });
                setUpdatedFilters(newArr);
            } else {
                setUpdatedFilters(filters.items);
            }
        }
    }, [isLoadedPlaces]); // eslint-disable-line

    const handleFilter = (e, id) => {
        let values = [];

        if (id === 'tax_country') {
            values.push(e.value);
            setSelected({
                ...selected,
                [id]: [e]
            });
            setParams({ ...params, [id]: e ? e.value : '' });
        } else {
            if (Array.isArray(e)) {
                e && e.map(({ value }) => values.push(value));
                setSelected({
                    ...selected,
                    [id]: e
                });
            } else {
                setSelected({
                    ...selected,
                    [id]: ''
                });
            }
            setParams({ ...params, 'from': 0, [id]: values.join() });
        }
        areJobsFromSearch(true);
    };

    const [resultsFilters, setResultsFilters] = useState([])

    const FilteringInput = () => {
        let array = [];
        if (resultsSearch.data && filters && filters.items && filters.items.length>0 ) {
            for (let y = 0; y < filters.items.length; y++) {
                for (let i = 0; i < resultsSearch.data.filters.list.length; i++) {
                    if (resultsSearch.data.filters.list[i].id === filters.items[y].id) {
                        array.push(resultsSearch.data.filters.list[i])
                    }
                }
            }

            setResultsFilters(array)
        }
    }

    useEffect(() => {
        FilteringInput();
    }, [resultsSearch])


    // Event on remove filter
    const handleRemove = (taxo, value) => {
        if (params[taxo]) {
            let list = params[taxo].split(',');
            let newParams = list.filter(item => item !== value);

            setParams({
                ...params,
                'from': 0,
                [taxo]: newParams.join()
            });

            let newSelected = selected[taxo].filter(item => item.value !== value);
            setSelected({
                ...selected,
                [taxo]: newSelected
            });

            areJobsFromSearch(true);
        }
    };

    useEffect(() => {
        setValues(selected);
    }, [selected]);

    useEffect(() => {
        setValues(selected);
    }, []);

    useEffect(() => {
        if (!selectIsOpen
            && (JSON.stringify(values[idFilter]) !== JSON.stringify(selected[idFilter]) || (JSON.stringify(values) !== JSON.stringify(selected)))
            && (needChange || idFilter === "tax_country")) {
            handleFilter(values, idFilter);
        }

        setNeedChange(false);

    }, [selectIsOpen])

    useEffect(() => {
        setNeedChange(true);
    }, [values])

    useEffect(() => {
        let index = 0;
        let path = '';
        let selection = "";
        for (const filter in params) { // loop sur les params

            if (filter !== 'lang' && filter !== 'from' && filter !== 'size' && filter !== 'userID' && params[filter] && params[filter] !== '') {// je ne prends que les paramètres remplis sauf lang et userID

                let arrayOfOptions = params[filter].split(',');
                let optionsList = filters.items.find(x => x.id === filter);
                let e = [];
                let item = {};
                arrayOfOptions.map((v) => { // eslint-disable-line
                    if (optionsList) {
                        let getOption = optionsList.choices.filter(item => item.value === v);
                        if (getOption[0]) {
                            item = {
                                'label': [getOption[0].label].toString(),
                                'value': v
                            };
                            e.push(item);
                        }
                    }
                });

                if (e.length) {
                    selection = {
                        ...selection,
                        [filter]: e
                    };
                }


                if (index === 0) {
                    path = `?${filter}=${params[filter]}`;
                } else {
                    path += `&${filter}=${params[filter]}`
                }


                index++;
            }
        }

        //setNewFilters({...cookiesFilters, offer: params});
        setSelected(selection);

        setSearchPath(path);
    }, [params]);




    useEffect(() => {
        if (path_filtered && path_filtered !== null) {
            let index = 0;
            let path = '';

            // If elevator pitch is filled, path_filtered contains filters
            if (Object.keys(path_filtered).length > 0) {
                for (const filter in path_filtered) { // loop sur les params
                    if (path_filtered[filter] !== '') {// je ne prends que les paramètres remplis sauf lang et userID
                        if (index === 0) {
                            path = `?${filter}=${path_filtered[filter]}`;
                        } else {
                            path += `&${filter}=${path_filtered[filter]}`
                        }
                        index++;
                    }
                }
                setSearchPath(path);
            }
        }
    }, [path_filtered]);

    const customPrimaryColor = colors?.primary;
    const haveCustomPrimaryColor = !!customPrimaryColor;

    const customTitleStyles = haveCustomPrimaryColor ? { color: customPrimaryColor } : {};
    const customButtonStyles = haveCustomPrimaryColor ? {
        backgroundColor: customPrimaryColor,
        borderColor: customPrimaryColor
    } : {};
    const customColorStyles = haveCustomPrimaryColor ? { color: `${customPrimaryColor} !important` } : {};

    const checkInclude = (data, value, filter) => {
        if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].value === value) {
                    return true;
                }
            }
        } else {
            for (let key in data) {
                let item = data[key];
                for (let i = 0; i < item.length; i++) {
                    if (item[i].value === value && key === filter) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    return (
        <>
            {(jobs.list && jobs.list.length > 0) &&
                <div className="c-offers paddingcontrol">
                    <div className="container">
                        <div className="c-offersFlexJustify">
                            <div className="title-50 c-offers-title" style={customTitleStyles}>{title}</div>
                            {(filters && Object.keys(filters).length > 0) &&
                                <>
                                    {(filters.items && filters.items.length > 0) &&
                                        <div className="c-filters" data-custom-color={haveCustomPrimaryColor}>
                                            <div className="c-filtersFlex">
                                                {filters.title &&
                                                    <div className="c-filters-label">{filters.title}&nbsp;:</div>
                                                }
                                                {(resultsFilters.length > 0 ? resultsFilters : filters.items).map(({ title, id, choices }, key) => {
                                                    return (
                                                        <div className="c-form-selectCtn" key={key}>
                                                            <label htmlFor={id} className="hidden">{title}</label>
                                                            <Select options={choices} placeholder={title}
                                                                styles={{
                                                                    ...customColorStyles,
                                                                    placeholder: styles => ({ ...styles, ...customColorStyles }),
                                                                    input: styles => ({ ...styles, ...customColorStyles }),
                                                                    indicator: styles => ({ ...styles, ...customColorStyles }),
                                                                    control: styles => ({ ...styles, ...customColorStyles })
                                                                }}
                                                                    className={`c-form-select ${id === 'tax_brands' ? 'uppercase' : ''}`}
                                                                id={id}
                                                                isSelected={true}
                                                                closeMenuOnSelect={id === 'tax_country'}
                                                                // menuIsOpen={true}
                                                                //value={(selected && selected.length > 0) ? selected[id] : values[id]}
                                                                value={choices.filter(obj => checkInclude(values, obj.value, id))}
                                                                isClearable={false}
                                                                hideSelectedOptions={false}
                                                                controlShouldRenderValue={false}
                                                                isMulti={id !== 'tax_country'}
                                                                classNamePrefix="c-form-select"
                                                                onChange={(e) => { setValues(e); setIdFilter(id) }}
                                                                onMenuOpen={() => setSelectIsOpen(true)}
                                                                onMenuClose={() => setSelectIsOpen(false)}
                                                            />
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        {(filters && Object.keys(filters).length > 0) &&
                            <>
                                {(filters.items && filters.items.length > 0) &&
                                    <>
                                        {selected &&
                                            <div className="c-search-offersRemove">
                                                <div className="container list-filters">
                                                    {Object.keys(selected).map((taxo) =>
                                                        selected[taxo] && selected[taxo].length > 0 &&
                                                        selected[taxo].map(({ label, value }) => {
                                                            return (
                                                                <button type="button" id={value}
                                                                    key={`${taxo}-${value}`}
                                                                    className="filter-toRemove"
                                                                    onClick={() => handleRemove(taxo, value)}
                                                                >
                                                                    <span className="filter-toRemove-label">{label}</span>
                                                                    <span className="icon-close"></span>
                                                                </button>
                                                            )
                                                        }
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        }
                        {(results && results.length > 0) ?
                            <div className="c-offersFlex">

                                {(map !== false && hide_map !== true) &&
                                    <CMap items={results}/>
                                }

                                <div className="c-offers-slider offers">
                                    <Swiper className="c-offers-sliderCtn"
                                        spaceBetween={18}
                                        speed={100}
                                        navigation
                                        autoHeight={true}
                                        pagination={{ clickable: true }}
                                        slidesPerView={1}
                                        grabCursor={true}
                                        noSwiping={false}
                                        scrollbar={{ draggable: true }}
                                        freeMode={false}
                                        resistance={false}
                                        freeModeMomentumRatio={1}
                                        freeModeMomentumVelocityRatio={0.5}
                                        freeModeMomentumBounceRatio={1}
                                        breakpoints={{
                                            768: {
                                                spaceBetween: 20,
                                                freeMode: true,
                                                slidesPerView: 'auto',
                                                slidesPerColumn: 2,
                                                autoHeight: false
                                            }
                                        }}
                                    >
                                        {
                                            results.slice(0, slice).map((offer, key) => {
                                                return (
                                                    <SwiperSlide key={key}>
                                                        <Offer {...offer} updateLikeComponents={updateLikeComponents}
                                                            rerender={rerender} path_filtered={searchPath}
                                                            customPrimaryColor={customPrimaryColor} />
                                                    </SwiperSlide>
                                                )
                                            })
                                        }
                                    </Swiper>
                                </div>
                            </div>
                            :
                            <>
                                {loading ?
                                    <div className="c-offers-noresults">{jobs.loading}</div>
                                    :
                                    <div className="c-offers-noresults">{jobs.no_results}</div>
                                }
                            </>
                        }
                        {(link && Object.keys(link).length > 0) && <div className="c-offers-button">
                            {link.title && (
                                link.url.indexOf("http") === 0 ?
                                    (
                                        <a
                                            href={`${link.url}${searchPath}`}
                                            title={link.title}
                                            className={`c-button`}
                                            target="_blank"
                                           // style={customButtonStyles}
                                           // data-custom-color={haveCustomPrimaryColor}
                                        >
                                            {link.title}
                                        </a>
                                    )
                                    :
                                    (
                                        <Link
                                            to={`${link.url}${searchPath}`}
                                            title={link.title}
                                            className={`c-button`}
                                            style={customButtonStyles}
                                            data-custom-color={haveCustomPrimaryColor}
                                        >
                                            {link.title}
                                        </Link>
                                    )
                            )}
                        </div>}
                    </div>
                </div>
            }
        </>
    )
};

export default Offers;