import React, { useState, useRef, useEffect } from "react";

// Libraries
import Select from 'react-select';

// Components
import { Link } from "react-router-dom";

const SearchOffers = ({ title, filters, link, offersPage, searchPage, selected, placeholder_search, value, handleChange, handleFilters, handleRemove, searchPath, restricted, customPrimaryColor }) => {
    const [opened, setOpened] = useState(false);
    const customColorStyles = !!customPrimaryColor ? { color: customPrimaryColor } : {};
    const customButtonStyles = !!customPrimaryColor ? {
        backgroundColor: customPrimaryColor,
        borderColor: customPrimaryColor
    } : {};

    if (filters && filters.list) {
        filters.list.forEach(l => l.choices.forEach(c => {
            if (c.label === "") {
                c.label = c.value
            }
        }))
    }

    return (
        <form
            className={`c-search-offers c-form ${offersPage ? 'c-search-offers--main' : ''} ${searchPage ? 'c-search--results' : ''} ${opened ? 'opened' : ''} ${restricted ? 'opened' : ''}`}
            data-bg="grey" onSubmit={e => e.preventDefault()}>

            <div className="container">
                <div className="c-search-offersFlex">
                    {title &&
                        <h3 className="title-40 c-search-offers-title" style={customColorStyles}>{title}</h3>
                    }

                    {filters && !restricted &&
                        <div className={`c-form-inputSearchCtn${customPrimaryColor ? ' default' : ''}`}>
                            <input type="text" placeholder={filters.inputs.placeholder_search} value={value}
                                onChange={(e) => handleChange(e.target.value)} />
                        </div>
                    }

                    {(offersPage && (filters && filters.list && filters.list.length > 2)) &&
                        <div className="c-button-ctn">
                            <button className="c-button" type="button" onClick={() => setOpened(!opened)}
                                data-custom-color={!!customButtonStyles} style={customButtonStyles}>
                                {filters.inputs.btn_filters}
                                <i className="icon-filter icon--right" />
                            </button>
                        </div>
                    }

                    {searchPage &&
                        <>
                            <div className={`c-form-inputSearchCtn${customPrimaryColor ? ' default' : ''}`}>
                                <input type="text" placeholder={placeholder_search} value={value}
                                    onChange={(e) => handleChange(e.target.value)} />
                            </div>
                            {/* C'est de l'autocomplétion donc pour moi le bouton rechercher est inutile. BaptCout */}
                            {/* <div className="c-button-ctn">
                                <button className="c-button" type="button">
                                    {btn_search}
                                </button>
                            </div> */}
                        </>
                    }
                </div>
            </div>

            {(filters && filters.list && filters.list.length > 0) &&
                <>
                    {offersPage ?
                        (
                            <>
                                <div className="c-search-offersHidden">
                                    <div className="c-search-offers-filters">
                                        <div className="container list-filters">
                                            <SelectList filters={filters.list} handleFilters={handleFilters}
                                                opened={restricted ? true : opened}
                                                selected={selected} restricted={restricted}
                                                customPrimaryColor={customPrimaryColor}
                                            />
                                            {/* C'est de l'autocomplétion donc pour moi le bouton rechercher est inutile. BaptCout */}
                                            {/* <div className="c-form-button">
                                                <button type="submit" className="c-button">{filters.inputs.btn_search}</button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                {selected &&
                                    <div className="c-search-offersRemove">
                                        <div className="container list-filters">
                                            {Object.keys(selected).map((taxo) =>
                                                selected[taxo] && selected[taxo].length > 0 &&
                                                selected[taxo].map(({ label, value }) => {
                                                    if (restricted) {
                                                        if (taxo === 'tax_brands') {
                                                            return (
                                                                <button type="button" id={value}
                                                                    key={`${taxo}-${value}`}
                                                                    className="filter-toRemove" disabled={true}>
                                                                    <span className="filter-toRemove-label">{label}</span>
                                                                </button>
                                                            )
                                                        } else {
                                                            return (
                                                                <button type="button" id={value}
                                                                    key={`${taxo}-${value}`}
                                                                    className="filter-toRemove"
                                                                    onClick={() => handleRemove(taxo, value)}>
                                                                    <span className="filter-toRemove-label">{label}</span>
                                                                    <span className="icon-close"></span>
                                                                </button>
                                                            )
                                                        }
                                                    } else {
                                                        return (
                                                            <button type="button" id={value}
                                                                key={`${taxo}-${value}`}
                                                                className="filter-toRemove"
                                                                onClick={() => handleRemove(taxo, value)}>
                                                                <span className="filter-toRemove-label">{label}</span>
                                                                <span className="icon-close"></span>
                                                            </button>
                                                        )
                                                    }
                                                }
                                                )
                                            )}
                                        </div>
                                    </div>
                                }
                            </>
                        ) : (
                            <div className="container list-filters">
                                <SelectList filters={filters.list} handleFilters={handleFilters} opened={opened}
                                    selected={selected} />
                                <div className="c-form-button">
                                    {/*<button type="submit" className="c-button"
                                            data-url={link.url}>{link.title}</button>*/}
                                    <Link to={link.url + searchPath} className={`c-button`}>
                                        {link.title}
                                    </Link>
                                </div>
                            </div>
                        )
                    }
                </>
            }
        </form>
    )
};

export default SearchOffers;


const SelectList = ({ filters, handleFilters, opened, selected, restricted, customPrimaryColor }) => {
    return (
        <div className={`c-search-offersFlex${customPrimaryColor ? ' default' : ''}`}>
            {
                filters.map((item, key) => (
                    <SelectListItem
                        {...item}
                        key={key}
                        selected={selected && selected[item.id]}
                        handleFilters={handleFilters} opened={opened}
                        restricted={restricted}
                    />
                ))
            }
        </div>
    )
};

const SelectListItem = ({ title, id, choices = [], selected, handleFilters, opened, restricted }) => {

    let ref = useRef(null);

    const [selectIsOpen, setSelectIsOpen] = useState(false);
    const [values, setValues] = useState([]);
    //
    // useEffect(() => {
    //     console.log('values', values);
    // }, [values]);

    useEffect(() => {
        //console.log('selected select', selected);
        if (Array.isArray(selected) && selected.length === 0) {
            setValues(selected);
        }
    }, [selected]);
    useEffect(() => {
        //console.log('selected select', selected);
        // setValues(selected);
        // if (Array.isArray(selected)) {
        //     if (selected.length === 0) {
        //         setValues([]);
        //     }
        // }
        setValues(selected);
    }, []);

    useEffect(() => {
        if (!selectIsOpen && values !== selected) {
            //  console.log('selectIsOpen status', selectIsOpen);
            handleFilters(values, id);
        }
    }, [selectIsOpen, values]);

    // if ((restricted && id === 'tax_brands') || id === 'tax_city' || id === 'tax_job' || id === 'tax_channel') {
    if ((restricted && id === 'tax_brands') || id === 'tax_job' || id === 'tax_channel') {
        return null
    }

    return (
        <div className={`c-form-selectCtn`}>
            <label htmlFor={id} className="hidden">{title}</label>
            <Select options={choices.length > 0 ? choices : []}
                // menuIsOpen={true}

                disabled={false}
                closeMenuOnSelect={id === 'tax_country'}
                isSelected={false}
                // value={(selected && selected.length > 0) ? selected : values}
                //     value={(Array.isArray(selected) && selected.length > 0) && selected}
                value={values}
                hideSelectedOptions={false}
                placeholder={title}
                isMulti={id !== 'tax_country'}
                id={id}
                ref={ref}
                classNamePrefix="c-form-select"
                onChange={(e) => {
                    //handleFilters(e, id);
                    setValues(e)
                }}
                onMenuOpen={() => setSelectIsOpen(true)}
                onMenuClose={() => setSelectIsOpen(false)}
                tabIndex={opened ? '0' : '-1'}
                className={`c-form-select ${id === 'tax_brands' ? 'uppercase' : ''}`}
            />
        </div>
    )
};