import React, { useState, useRef, useEffect } from 'react';

// Libraries
import useSearchApi from '../../Hooks/useSearchApi';

const Search = ({ url, title, setSearchIsOpen }) => {
    const inputEl = useRef(null);
    const formRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [type, setType] = useState('button');
    const [value, setValue] = useState('');

    // Get Search API results
    const [dataFetch, isLoaded] = useSearchApi({ value })

    // Event click on header's loupe
    const handleClick = () => {
        setOpen(!open)
        if (inputEl && open === false) {
            setTimeout(() => {
                inputEl.current.focus();
            }, 200);
        }
    }

    // Event when clic outside search value header 
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (formRef.current && !formRef.current.contains(event.target)) {
                if (value === "") {
                    setOpen(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [value]);

    // Event when input search is updated
    const handleChange = (event) => {
        setValue(event.target.value);
        if (event.key === 'Enter') {
            type === 'submit' && handleSubmit();
        } else {
            if (inputEl && open && event.target.value !== '') {
                setType('submit');
                setValue(event.target.value);
            } else {
                setType('button');
            }
        }
    }

    // Event on form submit
    const handleSubmit = (event) => {
        event.preventDefault();
        if (inputEl && inputEl.current.value !== '') {
            //console.log('search submit is ok');
            window.location.href = url + '?s=' + inputEl.current.value;
        }
    }

    // On form's open, set focus in input
    useEffect(() => {
        setSearchIsOpen(open);
        if (open === true && inputEl) {
            inputEl.current.focus();
        }
    }, [open]) // eslint-disable-line

    return (
        <form
            ref={formRef}
            className="header__search"
            method="get"
            onSubmit={handleSubmit}
            data-opened={open}
            autoComplete="off">
            <div className="header__search-wrapper">
                <label htmlFor="menu-search" className="hidden">{title}</label>
                <input ref={inputEl} type="search" id="menu-search" onChange={handleChange} placeholder={title} tabIndex={open ? '0' : '-1'} />
                <button type={type} className="icon-search" value="Rechercher" onClick={handleClick}></button>
            </div>
            <div className="autocomp__list">
                {isLoaded && dataFetch.code === 'all_good' && dataFetch.data.total > 0 ?
                    dataFetch.data.items.slice(0, 5).map((item, index) => <div className="autocomp__item" key={'autocomp__item-' + index}><a href={url + '?s=' + item.title}>{item.title}</a></div>)
                    :
                    (value !== '' && <div className="autocomp__item autocomp__item--noResults">Pas de résultats</div>)
                }
            </div>
        </form>
    )
}
export default Search;